exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-didgeridoo-jsx": () => import("./../../../src/pages/blog/didgeridoo.jsx" /* webpackChunkName: "component---src-pages-blog-didgeridoo-jsx" */),
  "component---src-pages-blog-fitness-jsx": () => import("./../../../src/pages/blog/fitness.jsx" /* webpackChunkName: "component---src-pages-blog-fitness-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-meditation-jsx": () => import("./../../../src/pages/blog/meditation.jsx" /* webpackChunkName: "component---src-pages-blog-meditation-jsx" */),
  "component---src-pages-blog-quotes-jsx": () => import("./../../../src/pages/blog/quotes.jsx" /* webpackChunkName: "component---src-pages-blog-quotes-jsx" */),
  "component---src-pages-blog-random-jsx": () => import("./../../../src/pages/blog/random.jsx" /* webpackChunkName: "component---src-pages-blog-random-jsx" */),
  "component---src-pages-blog-vipassana-jsx": () => import("./../../../src/pages/blog/vipassana.jsx" /* webpackChunkName: "component---src-pages-blog-vipassana-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

